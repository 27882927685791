@use "./variables";
@use "./base";
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  // font-size: 100%;
  scroll-behavior: smooth;
  // @media only screen and (max-width: 987px) {
  //   font-size: 72.5%;
  // }
  // @media only screen and (max-width: 687px) {
  //   font-size: 60.5%;
  // }
  // @media only screen and (max-width: 487px) {
  //   font-size: 55.5%;
  // }
}
body {
  overflow-x: hidden;
  overflow-y: auto;
  background: variables.$background;
  font-family: "Montserrat";
}
.error {
  color: red;
  font-size: 13px;
  padding: 5px 0;
}
