@use "./variables" as *;
.divider {
  height: 2.5rem;
}
.divider-md {
  height: 1.7rem;
}
a {
  text-decoration: none;
  color: $dark;
}
.section {
  padding: 1.8rem 3rem;
  @media only screen and (max-width: 750px) {
    padding: 1.5rem 1.8rem;
  }
  @media only screen and (max-width: 567px) {
    padding: 1.3rem;
  }
}
.section-title {
  font-size: 2.5rem;
  position: relative;
  font-weight: 600;
  &::before {
    position: absolute;
    content: "";
    height: 5px;
    width: 50px;
    background: #0e62ac;
    left: 5px;
    top: -8px;
  }
  @media only screen and (max-width: 750px) {
    font-size: 2rem;
  }
  @media only screen and (max-width: 567px) {
    font-size: 1.7rem;
  }
}
